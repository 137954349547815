import React, { Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'

import SEO from '../components/Seo'

const PrivacyPolicy = () => (
  <StaticQuery query={privacyPolicy} render={data => {
    const {
      header,
      seo: {
        frontmatter: {
          seo: seoInfo
        }
      },
      content,
      contactUs
    } = data
    return (
      <Fragment>
        <SEO data={seoInfo} />
        <div className="privacy-policy-page">
          <section className="privacy-policy-content">
            <h1 className="privacy-policy-header">{header.frontmatter.header}</h1>
            <div
              className="privacy-policy-text list-style general-link-style"
              dangerouslySetInnerHTML={{ __html: content.html }} />
          </section>
          <section className="contact-us-section">
            <div
              className="contact-us-content wrapper"
              dangerouslySetInnerHTML={{ __html: contactUs.html }} />
          </section>
        </div>
      </Fragment>
    )
  }} />
)

export default PrivacyPolicy

export const privacyPolicy = graphql`
  query {
    header: markdownRemark (
      fileAbsolutePath: {regex: "/content/additional-content/privacy-policy/header.*/"}
    ) {
      frontmatter {
        header
      }
    }
    seo: markdownRemark (
      fileAbsolutePath: {regex: "/content/additional-content/privacy-policy/seo-privacy-policy.*/"}
    ) {
      frontmatter {
        seo {
          canonical
          title
          description
          keywords
          robots
          image {
            childImageSharp {
              original {
                src
              }
            }
          }
          imageAlt
          twitterCard
        }
      }
    }
    content: markdownRemark (
      fileAbsolutePath: {regex: "/content/additional-content/privacy-policy/content.*/"}
    ) {
      html
    }
    contactUs: markdownRemark (
      fileAbsolutePath: {regex: "/content/additional-content/privacy-policy/contact-us-section.*/"}
    ) {
      html
    }
  }
`
